import { cases, sections, useResource } from '@/services/resources'
import { ref, computed } from '@vue/composition-api'
import { differenceBy, sortBy } from 'lodash'

const useSectionCases = (sectionId) => {
  const {
    can,
    loading,
    callRequest: getSectionCasesRequest
  } = useResource(sections.getCases)

  const sectionCases = ref([])

  const getSectionCases = async () => {
    const [err, res] = await getSectionCasesRequest({ urlParams: { id: sectionId } })
    if (err) return

    return res
  }

  const backToFrontMapper = (value) => {
    return sortBy(value, 'position').map(({ chest, is_hidden }) => ({ ...chest, is_hidden }))
  }

  return {
    can,
    loading,
    sectionCases,

    getSectionCases,
    backToFrontMapper
  }
}

const useAllCases = () => {
  const {
    can,
    loading,
    callRequest: getCasesRequest
  } = useResource(cases.getAll)

  const allCases = ref([])
  const currentPage = ref(0)
  const hasNextPage = ref(false)

  const getAllCases = async (page) => {
    const payload = {
      params: {
        per_page: 50,
        page: page || currentPage.value + 1,
        include: 'chestSections.section,lastActiveGeneration'
      }
    }

    const [err, res] = await getCasesRequest(payload)
    if (err) return

    const { current_page, next_page_url } = res

    hasNextPage.value = !!next_page_url

    if (current_page === 1) {
      allCases.value = []
    }

    currentPage.value = current_page

    return res
  }

  const backToFrontMapper = (all, selected) => {
    return differenceBy(all, selected, 'id')
  }

  return {
    can,
    loading,

    hasNextPage,
    currentPage,

    allCases,
    getAllCases,
    backToFrontMapper
  }
}

const useSectionCasesStore = (sectionId) => {
  const {
    can: canGetSectionCases,
    loading: getSectionCasesLoading,

    sectionCases,
    getSectionCases,
    backToFrontMapper: sectionCasesBackToFrontMapper
  } = useSectionCases(sectionId)

  const {
    can: canGetCases,
    loading: getCasesLoading,

    allCases,
    getAllCases,
    backToFrontMapper: allCasesBackToFrontMapper,

    hasNextPage: allCasesHasNextPage
  } = useAllCases()

  const removeFromSelected = (chest) => {
    const position = sectionCases.value.indexOf(chest)
    sectionCases.value.splice(position, 1)
    allCases.value.push(chest)
  }

  const addToSelected = (chest) => {
    const position = allCases.value.indexOf(chest)
    allCases.value.splice(position, 1)
    sectionCases.value.push(chest)
  }

  const initCategoriesRequest = async () => {
    Promise.all([
      getSectionCases(),
      getAllCases()
    ]).then(([selected, all]) => {
      if (selected) {
        sectionCases.value = sectionCasesBackToFrontMapper(selected.data)
      }
      if (all) {
        allCases.value = allCasesBackToFrontMapper(all.data, sectionCases.value)
      }
    }).catch()
  }

  const loadMoreAllCases = async () => {
    const res = await getAllCases()
    allCases.value.push(...allCasesBackToFrontMapper(res.data, sectionCases.value))
  }

  const {
    can: canUpdateCases,
    loading: updateCasesLoading,
    callRequest: updateCasesRequest
  } = useResource(sections.updateCases)

  const updateCases = async () => {
    const data = {
      chests: sectionCases.value.map(({ id, is_hidden = false }, position) => ({
        chest_id: id,
        is_hidden,
        position: position + 1
      }))
    }
    await updateCasesRequest({ urlParams: { id: sectionId }, data })
  }

  const existGenerationInSectionCases = computed(() => {
    const casesGeneration = sectionCases.value.map(({ last_active_generation: { is_failed = true } = {} }) => {
      return !is_failed
    })

    return !casesGeneration.includes(false)
  })

  return {
    initCategoriesRequest,

    sectionCases,
    canGetSectionCases,
    getSectionCasesLoading,

    allCases,
    canGetCases,
    getCasesLoading,

    removeFromSelected,
    addToSelected,

    allCasesHasNextPage,
    loadMoreAllCases,

    existGenerationInSectionCases,
    canUpdateCases,
    updateCasesLoading,
    updateCases
  }
}

export default useSectionCasesStore
