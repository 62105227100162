<template>
  <vx-card
    :loading="getCasesLoading"
    :can="canGetCases"
    no-body
  >
    <template #title>
      Available categories
    </template>
    <b-card-body>
      <draggable
        :list="allCases"
        tag="div"
        class="d-flex flex-wrap justify-content-center"
        group="cases"
      >
        <case-item-card
          v-for="(item, id) in allCases"
          :key="id"
          :chest="item"
          class="m-50"
          add-button
          @add="addToSelected"
        />
      </draggable>

      <div
        v-if="allCasesHasNextPage"
        class="w-100 mt-1 d-flex justify-content-center"
      >
        <vx-button
          variant="primary"
          @click="loadMoreAllCases"
        >
          Load more
        </vx-button>
      </div>
    </b-card-body>
  </vx-card>
</template>

<script>
import VxButton from '@/components/buttons/VxButton'
import VxCard from '@/components/VxCard'
import { BCardBody } from 'bootstrap-vue'
import Draggable from 'vuedraggable'
import { inject } from '@vue/composition-api'
import CaseItemCard from '@/views/sections/CaseItemCard'

export default {
  name: 'SectionCasesAvailable',
  components: {
    CaseItemCard,
    VxButton,
    VxCard,
    BCardBody,
    Draggable
  },
  setup () {
    const {
      allCases,
      canGetCases,
      getCasesLoading,

      addToSelected,

      allCasesHasNextPage,
      loadMoreAllCases
    } = inject('sectionCasesStore')

    return {
      allCases,
      canGetCases,
      getCasesLoading,

      addToSelected,

      allCasesHasNextPage,
      loadMoreAllCases
    }
  }
}
</script>
