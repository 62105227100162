<template>
  <vx-card
    :loading="getSectionCasesLoading"
    :can="canGetSectionCases"
    no-body
  >
    <template #title>
      Selected cases
    </template>

    <b-card-body>
      <draggable
        :list="sectionCases"
        tag="div"
        class="d-flex flex-wrap justify-content-center"
        group="cases"
      >
        <case-item-card
          v-for="(item, id) in sectionCases"
          :key="id"
          :chest="item"
          class="m-50"
          remove-button
          is-hidden-checkbox
          @remove="removeFromSelected"
        />
      </draggable>
    </b-card-body>
  </vx-card>
</template>

<script>
import { inject } from '@vue/composition-api/dist/vue-composition-api'
import VxButton from '@/components/buttons/VxButton'
import VxCard from '@/components/VxCard'
import { BCardBody } from 'bootstrap-vue'
import Draggable from 'vuedraggable'
import CaseItemCard from '@/views/sections/CaseItemCard'

export default {
  name: 'SectionCasesSelected',
  components: {
    CaseItemCard,
    VxButton,
    VxCard,
    BCardBody,
    Draggable
  },
  setup () {
    const {
      sectionCases,
      canGetSectionCases,
      getSectionCasesLoading,

      removeFromSelected
    } = inject('sectionCasesStore')

    return {
      sectionCases,
      canGetSectionCases,
      getSectionCasesLoading,

      removeFromSelected
    }
  }
}
</script>
