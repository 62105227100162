<template>
  <vx-card no-body>
    <template #title>
      Cases
    </template>

    <template #actions>
      <vx-button
        variant="primary"
        :can="canUpdateCases"
        :disabled="!existGenerationInSectionCases"
        :loading="updateCasesLoading"
        @click="updateCases"
      >
        Save
      </vx-button>
    </template>

    <b-card-body>
      <b-alert v-if="!existGenerationInSectionCases" show variant="danger">
        Case without generation disallowed to add in section.
      </b-alert>

      <section-cases-selected />
      <section-cases-available />
    </b-card-body>
  </vx-card>
</template>

<script>
import VxCard from '@/components/VxCard'
import VxButton from '@/components/buttons/VxButton'
import useSectionCasesStore from '@/views/sections/useSectionCasesStore'
import { provide, onBeforeMount } from '@vue/composition-api'
import { BCardBody, BAlert } from 'bootstrap-vue'
import SectionCasesSelected from '@/views/sections/SectionCasesSelected'
import SectionCasesAvailable from '@/views/sections/SectionCasesAvailable'

export default {
  name: 'SectionCases',
  components: {
    SectionCasesAvailable,
    SectionCasesSelected,
    VxButton,
    VxCard,
    BCardBody,
    BAlert
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  setup ({ id }) {
    const sectionCasesStore = useSectionCasesStore(id)
    provide('sectionCasesStore', sectionCasesStore)

    const {
      initCategoriesRequest,
      canUpdateCases,
      existGenerationInSectionCases,
      updateCasesLoading,
      updateCases
    } = sectionCasesStore

    onBeforeMount(() => {
      initCategoriesRequest()
    })

    return {
      existGenerationInSectionCases,
      canUpdateCases,
      updateCasesLoading,
      updateCases
    }
  }
}
</script>
