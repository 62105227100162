var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',[_c('vx-form',{attrs:{"resource":_vm.formResource},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('vx-select-resource',{attrs:{"resource":_vm.typesResource,"label":"Title content type","name":"question_title_content_id"},model:{value:(_vm.formValue.typeTitleId),callback:function ($$v) {_vm.$set(_vm.formValue, "typeTitleId", $$v)},expression:"formValue.typeTitleId"}}),_c('vx-select',{attrs:{"options":_vm.formValue.titleContents,"rules":_vm.rules.title_content_id,"reduce":function (ref) {
	var id = ref.id;

	return id;
},"options-label":"title","label":"Title","name":"title_content_id"},model:{value:(_vm.formValue.title_content_id),callback:function ($$v) {_vm.$set(_vm.formValue, "title_content_id", $$v)},expression:"formValue.title_content_id"}}),_c('vx-select-resource',{attrs:{"resource":_vm.typesResource,"label":"Description content type","name":"question_description_content_id","clearable":""},model:{value:(_vm.formValue.typeDescriptionId),callback:function ($$v) {_vm.$set(_vm.formValue, "typeDescriptionId", $$v)},expression:"formValue.typeDescriptionId"}}),_c('vx-select',{attrs:{"options":_vm.formValue.descriptionContents,"rules":_vm.formValue.is_framed ? _vm.rules.description_content_id : '',"reduce":function (ref) {
	var id = ref.id;

	return id;
},"options-label":"title","label":"Description","name":"description_content_id","clearable":""},model:{value:(_vm.formValue.description_content_id),callback:function ($$v) {_vm.$set(_vm.formValue, "description_content_id", $$v)},expression:"formValue.description_content_id"}}),_c('vx-select',{attrs:{"options":_vm.framedOptions,"rules":_vm.rules.is_framed,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options-label":"label","label":"Frame","name":"is_framed"},model:{value:(_vm.formValue.is_framed),callback:function ($$v) {_vm.$set(_vm.formValue, "is_framed", $$v)},expression:"formValue.is_framed"}}),(_vm.isFramed)?_c('div',[_c('div',{staticClass:"d-flex flex-row justify-content-between"},[_c('div',[_c('label',[_vm._v("From frame color")]),_c('chrome-picker',{staticClass:"mb-1",attrs:{"rules":_vm.rules.frame_color},model:{value:(_vm.formValue.from_frame_color),callback:function ($$v) {_vm.$set(_vm.formValue, "from_frame_color", $$v)},expression:"formValue.from_frame_color"}})],1),_c('div',[_c('label',[_vm._v("To frame color")]),_c('chrome-picker',{staticClass:"mb-1",attrs:{"rules":_vm.rules.frame_color},model:{value:(_vm.formValue.to_frame_color),callback:function ($$v) {_vm.$set(_vm.formValue, "to_frame_color", $$v)},expression:"formValue.to_frame_color"}})],1)]),_c('vx-image-uploader',{attrs:{"rules":_vm.rules.bg_file_id,"initial-src":_vm.initialImageUrl,"name":"bg_file_id","label":"Background"},model:{value:(_vm.formValue.bg_file_id),callback:function ($$v) {_vm.$set(_vm.formValue, "bg_file_id", $$v)},expression:"formValue.bg_file_id"}}),_c('vx-date-picker',{attrs:{"rules":_vm.rules.finish_at,"name":"finish_at","label":"Finish date and time","type":"datetime","disabled":_vm.isFinishAtDisabled},model:{value:(_vm.formValue.finish_at),callback:function ($$v) {_vm.$set(_vm.formValue, "finish_at", $$v)},expression:"formValue.finish_at"}})],1):_vm._e(),_c('vx-select',{attrs:{"options":_vm.caseSectionLocations,"rules":_vm.rules.location,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options-label":"label","label":"Location","name":"location"},model:{value:(_vm.formValue.location),callback:function ($$v) {_vm.$set(_vm.formValue, "location", $$v)},expression:"formValue.location"}}),_c('vx-checkbox',{attrs:{"name":"is_enabled","label":"Enabled"},model:{value:(_vm.formValue.is_enabled),callback:function ($$v) {_vm.$set(_vm.formValue, "is_enabled", $$v)},expression:"formValue.is_enabled"}}),_c('vx-button',{attrs:{"loading":loading,"variant":"primary","type":"submit"}},[_vm._v(" Save ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }