<template>
  <b-row class="justify-content-center">
    <b-col cols="12" lg="5">
      <section-editor :section="section" />
    </b-col>
    <b-col v-if="section" cols="12" lg="7">
      <section-cases :id="section.id" />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import VxCard from '@/components/VxCard'
import SectionEditor from '@/views/sections/SectionEditor'
import SectionCases from '@/views/sections/SectionCases'

export default {
  name: 'Section',
  components: {
    SectionCases,
    BCol,
    BRow,
    VxCard,
    SectionEditor
  },
  props: {
    dataResolverResponse: {
      type: Object,
      default: null
    }
  },
  setup ({ dataResolverResponse: section }) {
    return {
      section
    }
  }
}
</script>
