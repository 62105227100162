<template>
  <vx-card>
    <vx-form
      v-slot="{ loading }"
      :resource="formResource"
      @submit="handleSubmit"
    >
      <vx-select-resource
        v-model="formValue.typeTitleId"
        :resource="typesResource"
        label="Title content type"
        name="question_title_content_id"
      />

      <vx-select
        v-model="formValue.title_content_id"
        :options="formValue.titleContents"
        :rules="rules.title_content_id"
        :reduce="({ id }) => id"
        options-label="title"
        label="Title"
        name="title_content_id"
      />

      <vx-select-resource
        v-model="formValue.typeDescriptionId"
        :resource="typesResource"
        label="Description content type"
        name="question_description_content_id"
        clearable
      />

      <vx-select
        v-model="formValue.description_content_id"
        :options="formValue.descriptionContents"
        :rules="formValue.is_framed ? rules.description_content_id : ''"
        :reduce="({ id }) => id"
        options-label="title"
        label="Description"
        name="description_content_id"
        clearable
      />

      <vx-select
        v-model="formValue.is_framed"
        :options="framedOptions"
        :rules="rules.is_framed"
        :reduce="({ value }) => value"
        options-label="label"
        label="Frame"
        name="is_framed"
      />

      <div v-if="isFramed">
        <div class="d-flex flex-row justify-content-between">
          <div>
            <label>From frame color</label>
            <chrome-picker
              v-model="formValue.from_frame_color"
              :rules="rules.frame_color"
              class="mb-1"
            />
          </div>
          <div>
            <label>To frame color</label>
            <chrome-picker
              v-model="formValue.to_frame_color"
              :rules="rules.frame_color"
              class="mb-1"
            />
          </div>
        </div>

        <vx-image-uploader
          v-model="formValue.bg_file_id"
          :rules="rules.bg_file_id"
          :initial-src="initialImageUrl"
          name="bg_file_id"
          label="Background"
        />

        <vx-date-picker
          v-model="formValue.finish_at"
          :rules="rules.finish_at"
          name="finish_at"
          label="Finish date and time"
          type="datetime"
          :disabled="isFinishAtDisabled"
        />
      </div>

      <vx-select
        v-model="formValue.location"
        :options="caseSectionLocations"
        :rules="rules.location"
        :reduce="({ value }) => value"
        options-label="label"
        label="Location"
        name="location"
      />

      <vx-checkbox
        v-model="formValue.is_enabled"
        name="is_enabled"
        label="Enabled"
      />

      <vx-button
        :loading="loading"
        variant="primary"
        type="submit"
      >
        Save
      </vx-button>
    </vx-form>
  </vx-card>
</template>

<script>
import { Chrome } from 'vue-color'
import {
  VxCheckbox,
  VxDatePicker,
  VxForm,
  VxImageUploader,
  VxInput,
  VxSelect,
  VxSelectResource
} from '@/components/form'
import { VxButton } from '@/components/buttons'
import { computed, onBeforeMount, reactive, watch } from '@vue/composition-api'
import {
  caseSectionLocations,
  contents,
  digitalOceanPath,
  passDataToResource,
  sections,
  useResource
} from '@/services/resources'
import { isEmpty, isNil } from 'lodash'
import { setValuesToForm } from '@/services/form'
import router, { buildRoute, path } from '@/router'
import VxCard from '@/components/VxCard'

export default {
  name: 'SectionEditor',
  components: {
    VxCard,
    VxForm,
    VxCheckbox,
    VxDatePicker,
    VxInput,
    VxButton,
    VxSelect,
    VxSelectResource,
    VxImageUploader,
    'chrome-picker': Chrome
  },
  props: {
    section: {
      type: Object,
      default: null
    }
  },
  setup ({ section }) {
    const isEdit = !!section

    const framedOptions = [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' }
    ]

    const isFramed = computed(() => formValue.is_framed !== framedOptions['1'].value)
    const isFinishAtDisabled = computed(() => !formValue.is_enabled)

    const contentsResource = async (id) => {
      const params = { filter: { type: id }, per_page: 100 }
      const { can, callRequest } = useResource(contents.getAll)

      if (can) {
        const [, res] = await callRequest({ params })

        return res
      }
    }

    const contentsGetOne = async (id) => {
      const { can, callRequest } = useResource(contents.getOne)

      if (can) {
        const [, res] = await callRequest({
          urlParams: { id },
          params: null
        })

        return res
      }
    }

    const getFramedFields = () => {
      const { bg_file_id, from_frame_color, to_frame_color, finish_at } = formValue
      return {
        ...(!isNil(bg_file_id) && { bg_file_id }),
        ...(!isEmpty(from_frame_color) && { from_frame_color: from_frame_color.hex8 }),
        ...(!isEmpty(to_frame_color) && { to_frame_color: to_frame_color.hex8 }),
        finish_at
      }
    }

    const frontToBackMapper = () => {
      const { title_content_id, description_content_id, is_framed, is_enabled, location } = formValue
      return {
        title_content_id,
        description_content_id,
        is_framed,
        is_enabled,
        location,
        ...(is_framed && { ...getFramedFields() })
      }
    }

    const backToFrontMapper = (data) => {
      return {
        ...data,
        ...(isNil(data?.from_frame_color) && { from_frame_color: defaultFramesColor }),
        ...(isNil(data?.to_frame_color) && { to_frame_color: defaultFramesColor })
      }
    }

    const typesResource = contents.getTypes

    const formResource = isEdit
      ? passDataToResource(sections.update, {
        frontToBackMapper,
        requestParams: { urlParams: { id: section.id } }
      })
      : passDataToResource(sections.create, {
        frontToBackMapper
      })

    const defaultFramesColor = { hex: '#F7A97E', hex8: '#F7A97EFF' }

    const initialImageUrl = computed(() => {
      return section?.bg_file
        ? digitalOceanPath(section.bg_file?.path)
        : ''
    })

    const formValue = reactive({
      title_content_id: null,
      description_content_id: null,
      is_framed: framedOptions['1'].value,
      from_frame_color: defaultFramesColor,
      to_frame_color: defaultFramesColor,
      bg_file_id: null,
      finish_at: '',
      is_enabled: false,
      location: null,

      typeTitleId: null,
      titleContents: [],
      typeDescriptionId: null,
      descriptionContents: []
    })

    const rules = {
      title_content_id: {
        required: true
      },
      description_content_id: {
        required: true
      },
      is_framed: {
        required: true
      },
      frame_color: {
        required: true
      },
      finish_at: {
        more_than_today: true
      },
      location: {
        required: true
      }
    }

    watch(() => formValue.typeTitleId, () => {
      if (formValue.typeTitleId) {
        contentsResource(formValue.typeTitleId).then(res => {
          formValue.titleContents = res.data
        })
      }
    })

    watch(() => formValue.typeDescriptionId, () => {
      if (formValue.typeDescriptionId) {
        contentsResource(formValue.typeDescriptionId).then(res => {
          formValue.descriptionContents = res.data
        })
      }
    })

    watch(() => formValue.is_enabled, () => {
      if (!formValue.is_enabled) formValue.finish_at = null
    })

    if (isEdit) setValuesToForm(backToFrontMapper(section), formValue)

    onBeforeMount(() => {
      if (isEdit) {
        const { title_content_id, description_content_id } = section

        if (title_content_id) {
          contentsGetOne(title_content_id).then(res => {
            formValue.typeTitleId = res.data.type
          })
        }

        if (description_content_id) {
          contentsGetOne(description_content_id).then(res => {
            formValue.typeDescriptionId = res.data.type
          })
        }
      }
    })

    const handleSubmit = ([err, res]) => {
      if (err) return

      if (!isEdit) {
        router.push(buildRoute(path.section, { params: { id: res.data.id } }))
      }
    }

    return {
      isEdit,

      isFinishAtDisabled,

      isFramed,
      framedOptions,

      caseSectionLocations,

      typesResource,

      initialImageUrl,

      formValue,
      formResource,
      rules,
      handleSubmit
    }
  }
}
</script>
<style scoped>
.vc-chrome {
  width: 230px;
  box-shadow: none;
}
.vc-chrome >>> .vc-chrome-body {
  border: 1px solid rgba(60, 60, 60, 0.26) !important;
  border-radius: 0 0 4px 4px;
}
</style>
