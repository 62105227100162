<template>
  <div class="case-card card width-180 mb-0">
    <div class="overlay-img-card">
      <b-img-lazy
        v-if="imageUrl"
        fluid
        class="card-img-top p-2"
        :src="imageUrl"
      />
      <div class="card-img-overlay">
        <div
          v-if="isHiddenCheckbox"
          class="is-hidden-toggler"
        >
          <vx-checkbox
            v-model="chest.is_hidden"
            :name="checkboxName"
            label="Is hidden"
          />
        </div>
        <div class="event">
          <feather-icon
            v-if="addButton && !isDisabled"
            icon="PlusCircleIcon"
            class="text-success add-btn"
            @click="$emit('add', chest)"
          />
          <feather-icon
            v-if="removeButton"
            icon="MinusCircleIcon"
            class="text-danger remove-btn"
            @click="$emit('remove', chest)"
          />
        </div>
        <div
          v-if="alreadyConnectedSections"
          v-b-tooltip.hover.top="`Used in sections: ${alreadyConnectedSections}`"
          class="used-in-sections"
        />
        <div
          v-if="isDisabled"
          v-b-tooltip.hover.top="`Case without generation disallowed to add in section`"
          class="without-generation"
        />
      </div>
    </div>
    <div class="p-1 d-flex flex-column">
      <span>{{ chest.title }}</span>
    </div>
  </div>
</template>

<script>
import { digitalOceanPath } from '@/services/resources'
import { computed } from '@vue/composition-api'
import { BImgLazy } from 'bootstrap-vue'
import VxCheckbox from '@/components/form/VxCheckbox'
import { isEmpty } from 'lodash'

export default {
  name: 'CaseItemCard',
  components: {
    VxCheckbox,
    BImgLazy
  },
  props: {
    addButton: Boolean,
    removeButton: Boolean,
    isHiddenCheckbox: Boolean,
    chest: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const imageUrl = computed(() => digitalOceanPath(props.chest.icon.path))

    const checkboxName = computed(() => `is_hidden-${props.chest.id}`)

    const alreadyConnectedSections = computed(() => {
      const chestSections = props.chest?.chest_sections
      return !!chestSections && !isEmpty(chestSections)
        ? chestSections.map(({ section: { title } }) => title).join(', ')
        : null
    })

    const isDisabled = computed(() => {
      const casesGeneration = [props.chest].map(({ last_active_generation: { is_failed = true } = {} }) => {
        return !is_failed
      })

      return casesGeneration.includes(false)
    })

    return {
      imageUrl,
      checkboxName,
      alreadyConnectedSections,
      isDisabled
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.case-card {
  .overlay-img-card {
    position: relative;

    div {
      &.is-hidden-toggler {
        position: absolute;
        top: 4px;
        left: 4px;
      }

      &.event {
        position: absolute;
        top: 3px;
        right: 4px;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          cursor: pointer;
        }
      }

      &.used-in-sections {
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $warning;
      }

      &.without-generation {
        position: absolute;
        bottom: 4px;
        left: 4px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $danger;
      }
    }
  }
}
</style>
